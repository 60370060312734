import * as Sentry from "@sentry/nuxt"

const runtimeConfig = useRuntimeConfig()

function getAppStateJson() {
  return localStorage.getItem("unitcrunch") ?? ""
}

if (runtimeConfig.public.sentry.enable) {
  Sentry.init({
    beforeSend: (event, hint) => {
      hint.attachments = [
        { filename: "uc-app-state.json", data: getAppStateJson() },
      ]
      return event
    },
    dsn: runtimeConfig.public.sentry.dsn,
    environment: runtimeConfig.public.appEnvironment,
    tracePropagationTargets: [
      /^https:\/\/.*--unitcrunch\.netlify\.app/,
      /^https:\/\/www\.unitcrunch\.com/,
      // /^http:\/\/.localhost:3000/,
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
  Sentry.setTag("app.version", runtimeConfig.public.clientVersion)
}
